
.text-colour {
  color: white;
  font-family: "Montserrat";
}
.text-danger {
  color: #FD8080 !important;
  font-style: italic;
  font-family: "Montserrat";
  font-size: 16px;
  text-align: start;
  margin-block-end: 0px;
  vertical-align: baseline;
  line-height: normal;
  padding-top: 10px;
}

.form-container{
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: black;
  color: white;
  border: 0vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  border-radius: 16px;
  padding: 12px 24px 12px 24px;
  flex-basis: content;
}
.form-container-row{
  display: flex;
  flex-direction: row;
}
.form-group{
  display: flex;
  flex-direction: row;
  /* width: 40%; */
  padding-right: 24px;
  flex-basis: content;
}

.form-group-sec{
  display: flex;
  flex-direction: row;
  /* width: 45%; */
  padding-right: 24px;
  flex-basis: content;
}

.input-container{
  width: 100%;
  border-radius:16px;
}
.form-control{
  box-shadow: none !important; 
  border-color: rgba(0,0,0,0.125) !important;
  border-radius: 8px !important; 
}

.form-control:hover{
  box-shadow: none !important; 
  border-color: rgba(0,0,0,0.125) !important; 
}

.input-control {
  background-color:rgba(217,217,217,255); 
  border: solid black;
  width: 207px;
}

.input-control:focus {
  background-color: white; 
}

.input-control.error {
  background-color: #FD8080; 
}
.form-button{
  width: 91px;
  background-color: white;
  border: solid black;
  color: black;
  border-radius: 8px;
  font-weight: bold;
}
.form-button:hover{
  width: 91px;
  background-color: gray;
  border: solid black;
  color: black;
  border-radius: 8px;

}
.form-button:active{
  width: 91px;
  background-color: gray;
  border: solid black;
  color: black;
  border-radius: 8px;

}
.form-button.error{
  width: 91spx;
  background-color: white;
  color: black;
  border-radius: 8px;
  max-height: 40px;
  border: solid black;
}