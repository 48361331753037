.map-container {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  /* justify-content: center; */
}
.mapboxgl-ctrl-attrib.mapboxgl-compact, .mapboxgl-ctrl-attrib.mapboxgl-compact::after, .mapboxgl-ctrl-attrib.mapboxgl-compact::before {
  box-sizing:content-box;
  background-color: #4460ee;
}
.mapboxgl-canvas-container.mapboxgl-interactive, .mapboxgl-ctrl-group  button.mapboxgl-ctrl-compass {
  cursor: default;

}
a.mapboxgl-ctrl-logo{
  display: none;
}

.header-container {
  position: fixed;
  width: 100%;

  display: flex;
  justify-content: center;
  z-index: 2;
  top: 10px;
}
#btn-spin {
  font: bold 12px/20px "Helvetica Neue", Arial, Helvetica, sans-serif;
  background-color: #3386c0;
  color: #fff;
  position: absolute;
  top: 20px;
  left: 50%;
  z-index: 1;
  border: none;
  width: 200px;
  margin-left: -100px;
  display: block;
  cursor: default;
  padding: 10px 20px;
  border-radius: 3px;
}
#btn-spin:hover {
  background-color: #4ea0da;
}
#toggleButton {
  position: fixed;
  z-index: 10;
  right: 60px;
  top: 90px;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 50%;
  cursor: default;
  border: none;
}
#toggleButton-user {
  position: fixed;
  z-index: 10;
  right: 60px;
  top: 100px;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 50%;
  cursor: default;
  border: none;
}
.spinner-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty-marker {
  background-image: url("/public/icons/Property 1=empty-marker.svg");
  background-size: cover;
  display: inline-block;
  color: white;
  font-weight: bold;
  height: 36px;
  width: 36px;
  display: flex; 
  align-items: center; 
  justify-content: center;
  border-radius: 50%;
  cursor: default;
}
.empty-marker:hover {
  background-image: url("/public/icons/Property 1=empty-marker-selected.svg");
  background-size: cover;
  display: inline-block;
  color: black;
  font-weight: bold;
  height: 36px;
  width: 36px;
  display: flex; 
  align-items: center; 
  justify-content: center;
  border-radius: 50%;
  cursor: default;
}
.cluster-text{
  color:white;
}
.cluster-text:hover{
  color:rgb(0, 0, 0);
 
}


.white-marker {
  background-image: url("/public/icons/Property 1=Waypoint.svg");
  /* background-size: cover; */
  width: 34px;
  height: 39px;
  /* border-radius: 50%; */
  cursor: default;
}
.white-marker:hover {
  background-image: url("/public/icons/Property 1=Waypoint-selected.svg");
  /* background-size: cover; */
  width: 34px;
  height: 39px;
  /* border-radius: 50%; */
  cursor: default;
}
.white-marker-selected{
  background-image: url("/public/icons/Property 1=Waypoint-selected.svg");
  /* background-size: cover; */
  width: 34px;
  height: 39px;
  /* border-radius: 50%; */
  cursor: default;
}
.purple-marker {
  background-image: url("/public/icons/Property 1=Snapshot.svg");
  /* background-size: cover; */
  width: 34px;
  height: 39px;
  /* border-radius: 50%; */
  cursor: default;
}
.purple-marker:hover {
  background-image: url("/public/icons/Property 1=Snapshot-selected.svg");
  /* background-size: cover; */
  width: 34px;
  height: 39px;
  /* border-radius: 50%; */
  cursor: default;
}
.purple-marker-selected {
  background-image: url("/public/icons/Property 1=Snapshot-selected.svg");
  /* background-size: cover; */
  width: 34px;
  height: 39px;
  /* border-radius: 50%; */
  cursor: default;
}
.yellow-marker {
  background-image: url("/public/icons/Property 1=Panorama.svg");
  /* background-size: cover; */
  width: 34px;
  height: 39px;
  /* border-radius: 50%; */
  cursor: default;
}
.yellow-marker:hover {
  background-image: url("/public/icons/Property 1=Panorama-selected.svg");
  /* background-size: cover; */
  width: 34px;
  height: 39px;
  /* border-radius: 50%; */
  cursor: default;
}
.yellow-marker-selected {
  background-image: url("/public/icons/Property 1=Panorama-selected.svg");
  /* background-size: cover; */
  width: 34px;
  height: 39px;
  /* border-radius: 50%; */
  cursor: default;
}
/* Loader */
.progress-bar-custom {
  height: 4px;
  background-color: rgba(5, 114, 206, 0.2);
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: rgb(92, 176, 245);
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}
   
  @keyframes indeterminateAnimation {
    0% {
      transform: translateX(0) scaleX(0);
    }
    40% {
      transform: translateX(0) scaleX(0.4);
    }
    100% {
      transform: translateX(100%) scaleX(0.5);
    }
  }

.cluster-marker {
  background: black;
  display: inline-block;
  color: white;
  font-weight: bold;
  height: 38px;
  width: 38px;
  display: flex; 
  align-items: center; 
  justify-content: center;
  border-radius: 50%;
  cursor: default;
}

.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: default;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 30px;
  height: 12px; 
  /* //newchange */
}

.slider.round:before {
  border-radius: 50%;
}

.close-icon {
  background-size: contain;
  background-repeat: no-repeat;
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;
  border-color: transparent;
  pointer-events: auto;
  cursor: default;
}
.close-svg{
  background-image: url("/public/icons/Close.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 1.3rem;
  height: 1.3rem;
  background-color: black;
  border-radius: 50%;
  border-color: transparent;
  pointer-events: auto;
  cursor: default;

}
.heading-icon {
  background-size: cover;
  width: 1.5rem;
  height: 1.5rem;
  padding-bottom: 10px;
  /* border-radius: 50%; */
  /* cursor: pointer; */
}

.plus-icon {
  background-image: url("/public/icons/plus-icon-bar.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 1rem;
  height: 1rem;
  background-color: #fff;
  border-radius: 50%;
  border-color: transparent;
  pointer-events: auto;
  cursor: default !important;
}

.negative-icon {
  background-image: url("/public/icons/minus-icon-bar.png");
  background-size: contain;
  width: 1rem;
  height: 1rem;
  background-color: #fff;
  border-radius: 50%;
  border-color: transparent;
  cursor: default;
  pointer-events: all;
  z-index: 11;
}

.drag-icon {
  pointer-events: auto;
  /* changed cursor to defaut from grab */
  cursor: default; 
  background-image: url("/public/icons/Drag-Selection-Icon.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 0.8rem;
  height: 0.9rem;
  margin-right: 0.375rem;
  /* margin-bottom: -0.375rem;  
    border-radius: 50%; */
}

.custom-popup {
  cursor: default;
  /* changed cursor to defaut from grab */
  user-select: none;
  position: relative;
  z-index: 999;
  background-color: transparent;
  padding: 0px;
  left: 100px;
  /* border: 1px solid #ccc; */
  border-radius: 25px;
}

#slider {
  position: absolute;
  /* width: 100px;
    height: 100px;
    background: blue; */
  transform: translateX(-6.5%);
  -webkit-transform: translateX(-6.5%);
}

/* .slide-in {
    animation: slide-in 0.6s forwards;
    -webkit-animation: slide-in 0.6s forwards;
  }
  @keyframes slide-in {
    100% {
      transform: translateX(0%);
    }
  }
  
  @-webkit-keyframes slide-in {
    100% {
      -webkit-transform: translateX(0%);
    }
  } */

.slidecontainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; /* Width of the outside container */
}

/* The slider itself */
.slider {
  /* -webkit-appearance: none; Override default CSS styles */
  /* height: 5px;
    width: 7px; */
  background: #fafafa;
  position: relative;
  bottom: 0%;
  left: 100%;
  height: 1rem;
  transform: translateY(50%) translateX(-100%);
  border-radius: 3px;
  display: flex;
  justify-content: center;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

.mapboxgl-popup-content {
  /* background: #fff; */
  /* display: none; */
  background-color: transparent;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0);
  padding: 10px 10px 15px;
  pointer-events: auto;
  position: relative;
}

.mapboxgl-popup-tip {
  /* border: 10px solid transparent; */
  display: none;
  height: 0;
  width: 0;
  z-index: 1;
}
.mapboxgl-ctrl-bottom-right {
  z-index: 10;
}
.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl {
  float: right;
  margin: 0 10px 40px 0;
} 
.mapboxgl-ctrl-group button:first-child {
  border-radius: 4cm 4cm 0 0;
  cursor: default;
}

.mapboxgl-ctrl-group button:last-child {
  border-radius: 4cm;
  margin-bottom: 0px;
}
.mapboxgl-ctrl-group {
  background-color: transparent;
  box-shadow: none !important;
  cursor: default;
}
.mapboxgl-ctrl-group button+button {
  border-top: none;
}
.mapboxgl-ctrl-attrib-button:focus, .mapboxgl-ctrl-group button:focus {
  box-shadow: none;
}
.mapboxgl-ctrl-attrib-button {
  background-image: url("/public/icons/Control-Info.svg") !important;  
  background-repeat: no-repeat;
  background-position: center center;
  background-color: gray !important; 
  
}
.mapboxgl-ctrl-attrib.mapboxgl-compact{

  right:50px !important;
  bottom: 46px !important; 
  border-radius: 50%;
  width: 0px ;
  height: 0px;
  background-color: gray;
  display: none;

}
.mapboxgl-ctrl-attrib.mapboxgl-compact-show{
  right:50px !important;
  bottom: 46px !important; 
  border-radius: 12px;
  width: 100% ;
  height: 100%;
  background-color: rgb(255, 255, 255);

}
.mapboxgl-ctrl-bottom-right {
  float: right;
  margin: 0;
  background: transparent;
}

.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
  background-image: url("/public/icons/Property 1=Zoom-bottom-button.svg") !important;
  background-size: 90% ;
} 
.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon:hover {
  background-image: url("/public/icons/Property 1=Zoom-bottom-button-hover.svg") !important;
  background-size: 100% ;
  cursor: default;

} 
.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
  background-image: url("/public/icons/Property 1=Default-Zoom-Top-button.svg") !important;
  background-size: 100% ;
}
.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon:hover {
  background-image: url("/public/icons/Property 1=Zoom-Top-button-hover.svg") !important;
  background-size: 100% ;
}

.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon{
  background-image: url("/public/icons/Property 1=Default-Compass.svg") !important; 
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgb(0, 0, 0);
  cursor: default;
  background-size: 100% ;
  border: none;
}
.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon:hover{
  background-image: url("/public/icons/Property 1=Compass-Hover.svg") !important;
  background-repeat: no-repeat;
  background-position: center center;
  /* background-color: rgb(0, 0, 0); */
  background-color:#343434 ;
  background: none;
  cursor: default;
  background-size: 100% ;
  border: none;
 
}

.mapboxgl-ctrl-bottom-right {
  bottom: 0;
  right: 50px;
}

.mapboxgl-ctrl-group .mapboxgl-ctrl-compass:hover{
  width: 30px ;
  height: 30px;
  border-radius: 50%;
  border-color:#5E5C60;
  background-color:#5E5C60 !important; 
  margin-top: 20px;
}
.mapboxgl-ctrl-group .mapboxgl-ctrl-compass{
  
  width: 30px ;
  height: 30px;
  border-radius: 50%;
  border-color: rgb(0, 0, 0);
  background-color: black !important; 
  margin-top: 20px;

}
.mapboxgl-ctrl-group .mapboxgl-ctrl-zoom-out{
 
  width: 30px;
  height: 40px;
  border-radius: 0 0 4cm 4cm;
  border-color: rgb(0, 0, 0);
  background-color: black !important;

}
.mapboxgl-ctrl-group .mapboxgl-ctrl-zoom-out:hover{
  width: 30px;
  height: 40px;
  border-radius: 0 0 4cm 4cm;
  border-color:#5E5C60;
  background-color:#5E5C60 !important;
}
.mapboxgl-ctrl-group .mapboxgl-ctrl-zoom-in{
  background-color: black !important;
  width: 30px;
  height: 40px;
  /* border-radius: 50%;
  border-color: rgb(0, 0, 0); */
}
.mapboxgl-ctrl-group .mapboxgl-ctrl-zoom-in:hover{
  background-color: rgba(128, 128, 128, 0.895);
  width: 30px;
  height: 40px;
  border-color:#5E5C60;
  background-color:#5E5C60 !important;
}

.LogoutIconUser {
  position: fixed;
  z-index: 10;
  right: 60px;
  top: 90px;
  width: 30px;
  height: 30px;
  padding-right: 7px;
  padding-top: 2px;
  padding-left: 5.5px;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgb(0, 0, 0);
  border-radius: 50%;
  cursor: default;
}
a img:last-child {
  display: none; 
}
a:hover img:last-child {
  display: inherit;  
}
a:hover img:first-child {
  display: none;  
}
#LogoutIcon {
  
  position: fixed;
  z-index: 10;
  right: 60px;
  top: 40px;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 50%;
  cursor: default;
  border: none;
}
#LogoutIcon-user {
  
  position: fixed;
  z-index: 10;
  right: 60px;
  top: 50px;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 50%;
  cursor: default;
  border: none;
}
.LogoutIcongallery {
  /* background-image: url("/public/icons/Control-Log-Out.svg"); */
  position: fixed;
  z-index: 9;
  right: 60px;
  top: 50px;
  width: 30px;
  height: 30px;
  padding-top: 4px;
  padding-right: 3px;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: gray;
  border-radius: 50%;
  cursor: default;
}
.polygonIcon {
  z-index: 10;
  right: 60px;
  top: 140px;
  width: 30px;
  height: 30px;
  padding-right: 8px; 
  background-repeat: no-repeat;
  background-position: center center;
  position: fixed;
  background-color: black;
  padding-top: 3px;
  padding-left: 8px;
  border-radius: 20px;
  cursor: default;
}

.polygonIcon-selected {
  z-index: 10;
  right: 60px;
  top: 140px;
  width: 30px;
  height: 30px;
  padding-right: 6px; 
  padding-top: 1.5px;
  padding-left: 6px;
  background-repeat: no-repeat;
  background-position: center center;
  position: fixed;
  background-color: white;
  border-radius: 50%;
  border: 2px solid black;
  box-sizing: border-box;
  cursor: default;

  
}
input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1); /* This inverts the colors, making the icon white */
  width: 24px;
  height: 20px;
}

.calender-icon {
  background-image: url("/public/icons/Tool-Bar-Calendar.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 1rem;
  height: 1.2rem;
  /* border-radius: 50%; */
  /* cursor: pointer; */
}
.calender-icon-bar {
  background-image: url("/public/icons/Tool-Bar-Calendar.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 1.1rem;
  height: 1.2rem;
  /* border-radius: 50%;
  cursor: pointer;  */
}

.thumbnail-landscape {
  background-image: url("/public/icons/Panorama-Icon.svg");
  background-size:contain;
  background-repeat: no-repeat;
  height: 1.1rem;
  width: 1.5rem;
  margin-bottom: -0.375rem;
}

.thumbnail-portrait {
  background-image: url("/public/icons/Snapshot-Icon.svg");
  background-size: contain;
  background-repeat: no-repeat;
  height: 1rem;
  width: 1rem;
}
.thumbnail-waypoint {
  background-image: url("/public/icons/Waypoint-Icon.svg");
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 2px;
  height: 1rem;
  width: 1rem;
  
}
.thumbnail-landscape-bar {
  background-image: url("/public/icons/Panorama-Icon.svg");
  background-size:contain;
  background-repeat: no-repeat;
  height: 1.1rem;
  width: 1.5rem;
  margin-bottom: -0.375rem;
  /* margin-top: 0.25rem;  */
}

.thumbnail-portrait-bar {
  background-image: url("/public/icons/Snapshot-Icon.svg");
  background-size: contain;
  background-repeat: no-repeat;
  height: 1rem;
  width: 1rem;
  /* margin-top: 0.25rem; */
}
.thumbnail-waypoint-bar {
  background-image: url("/public/icons/Waypoint-Icon.svg");
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 2px;
  height: 1rem;
  width: 1rem;
  
  /* margin-top: 0.25rem; */
}
.mobile-icon-bar {
  background-image: url("/public/icons/Tool-Bar-Mobile.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 1rem;
  height: 1.1rem;
  /* z-index: 9; */
}

.mobile-icon {
  background-image: url("/public/icons/Tool-Bar-Mobile.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 1rem;
  height: 1.1rem;
  /* z-index: 9; */
}

.slider1 {
  position: absolute;
  cursor: default;
  top: 4px;

  left: 0;
  right: 0;
  bottom: 0;
  background-color: #999999;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider1:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  top: 0.5px;
  /* bottom: 2px; */
  background-color: black;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider1 {
  background-color: white;
}

input:focus + .slider1 {
  box-shadow: 0 0 1px white;
}

input:checked + .slider1:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}

/* / Rounded sliders / */
.slider1.round {
  border-radius: 30px;
  height: 13px;
}

.slider1.round:before {
  border-radius: 50%;
}

.toggle-popup {
  /* pointer-events: auto;
  cursor: grab; */
  position: relative;
  z-index: 999;
  background-color: black;
  padding: 0px;
  /* / border: 1px solid #ccc; / */
  border-radius: 0.5rem;
}
/* div[disabled]
{
  pointer-events: none;
  opacity: 0.7;
} */
[aria-disabled="true"] {
  pointer-events: none;
  opacity: 0.5;
  /* background-color: #4460ee !important; */
}
