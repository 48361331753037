.carousel-container {
  position: relative;
  z-index: 10;
}
.carousel-img {
  position: absolute;
  z-index: 10;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.carousel-icon {
  color: white;
  pointer-events: all;
  cursor: pointer;
  padding-top: 4px;
  padding-left: 5px;
}
.carousal-bar-text {
  color: white;
  padding-top: 6px;
  padding-left: 5px;
  font-size: small;
}
.flex-controlbar-container {
  display: flex;
  background-color: black;
  z-index: 11;
}
.flex-container > div {
  margin: 5px;
  padding: 10px;
  font-size: 18px;
}
.carousel-curve-text {
  z-index: 13;
  margin-top: 6%;
  margin-left: -40%;
  position: relative;
}

.carousel-wrapper {
  width: 50%;
  height: 46%;
  border-radius: 50%;
  background: none;
  position: relative;

  /* margin-left: 10%; */
}
.carousel-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 51%;
}
.carousel-control-bar {
  
  display: flex;
  object-fit: cover;
  cursor: pointer;
  position: absolute;
  min-width: 70px;
  height: 28px;
  background: black;
  border-radius: 40px;
  text-align: center;
  left: 70%;
  top: 10%;

}
.carousel-curved-text {
  color: black;
  font-size: 100%;
  font-family: "georgia";
  font-weight: bold;
}
h1 {
  margin: 0;
}

img {
  border-style: none;
}

.wrapper {
  position: fixed;
  padding-top: 23%;
  width: 20%;
  height: 34%;
  margin-left: 15%;
}
.toptext {
  position: relative;
  font-size: 14px;
  font-family: "Montserrat";
  height: 105%;
}

.toptext span {
  margin-top: -50%;
  font-weight: bold;
  position: absolute;
  color: rgb(0, 0, 0);
  line-height: 36vh; 
  text-align: center;
  transform-origin: top center;
  
  
}
.icons{
  transform-origin: top left;

}
.inversed {
  font-weight: bold;
}
.inversed span {
  margin-left: -10%;
  font-family: "Montserrat";
  line-height: 36vh;
  /* transform-origin: bottom center; */
}
.logo{
  position: absolute;
  width:33vh;
  height:33vh;
  background:rgb(255, 255, 255);
  border-radius:50%;
  justify-content: centre;
  align-items: centre;

      
}
 .wrap{
  margin-left: 3%;
  padding: 4.5% 13% 5% 10%;
  display: flex;
  align-items: centre;
  justify-content: centre;
}

/* .wrap{
  padding: 10%;
  display: flex;
  align-items: centre;
  justify-content: centre;
  min-width: 40vh;
}
.logo{
  position: absolute;
  width:33vh;
  height:33vh;
  background:rgb(255, 255, 255);
  border-radius:50%;
  justify-content: centre;
  align-items: centre;

      
}

.toptext {
  position: relative;
  width: 220px;
  height: 200px;
  margin: 0 auto; 
  font-size: 30px;
}

.toptext span {
  position: absolute;
  color: aqua;
  line-height: 130px; 
  text-align: center;
  transform-origin: top center; 
}
.inversed {
  font-weight: bold;
}
.inversed span {
  line-height: 70px;
  transform-origin:  center;
} */
.wrapper h1 {
  font-family: "Amatic SC", cursive;
  font-size: 14px;
  color: rgb(38, 15, 142);
  text-align: center;
  position: absolute;
  left: 20%;

  /* I think this works for centering? */
  top: calc(50% - 64px);
  -webkit-transform: translate(-50%, calc(-50% - 64px));
  -ms-transform: translate(-50%, calc(-50% - 64px));
  transform: translate(-50%, calc(-50% - 64px));
}

.wrapper h1 span {
  position: absolute;
}

.image-gallery {
  z-index: 10000;
  pointer-events: all;
  width: 100%;
  height: 100%;
  background-color: black;
  top: -5%;
}

.close-gallery {
  z-index: 999;
  pointer-events: all;
  background-color: black;
  color: white;
  font-family: "Montserrat";
  font-weight: bold;
  width: 2rem;
  height: 2rem;
  border: 2px solid white;
  border-radius: 50%;

}

.image-gallery-view > ._2ILZE > ._2qwzr > ._3lwW_ > .__JnHV {
  background-color: #5E5C60 !important;
  border-radius: 20px;
  width: 50px;
  margin-top: 1.9rem;
  margin-left: 1rem;
  color: white;
  opacity: 1;
}


