/* @keyframes easeIn {
    0% { 
        opacity: 0;
        transform: translateX(-15%)
     }
    
    100% { 
        opacity: 1;
        transform: translateX(0%)
     }
  }
#slider {
    position: absolute;
    animation: easeIn 3s;

  }
   */

.heading-icon {
  background-image: url("/public/icons/Header-Icon.svg");
  background-size: cover;
  width: 0.9rem;
  height: 0.9rem;
}

.bottom-bar{
  margin-bottom: 10px;
}
