@import "react-date-range/dist/styles.css";
@import "react-date-range/dist/theme/default.css";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.css-vzjani {
  padding: 0px !important;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/static/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
