@keyframes easeIn {
  0% {
    opacity: 0;
    transform: translateX(-15%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
.desc-heading {
  z-index: 10;
  color: black;
  font-size:4vw;
  font-family: "Montserrat-Bold";
  font-weight: bold;
}
.desc-container {
  height: 30vh;
  min-width: 45vw;
  width: auto;
  text-align: left;
  margin-left: 10%;
}

.desc-content {
  z-index: 10;
  color: black;
  font-size:0.9vw;
  background-color: white;
  text-align: left;
  font-family: "Montserrat";
}

.desc-content-id {
  z-index: 10;
  color: black;
  background-color: white;
  margin-bottom: -0.25rem;
  width: fit-content;
  font-size: 0.9vw;
  text-align: left;
  font-family: "Montserrat";
}
