@font-face {
  font-family: "Montserrat-Bold";
  src: url("fonts/static/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("fonts/static/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
