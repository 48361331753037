.overlay-side-bar {
  display: flex;
  flex: none;
  transform: rotate(-90deg);
  transform-origin: 10%;
  margin-left: 1%;
  height: 20px;
  width: 30vw;
  background: transparent;
  border-radius: 40px;
  text-align: center;
  position: absolute;
  bottom: 15%;
}

.overlay-content {
  display: flex;
  flex: none;
  margin-right: 10px;
  color: black;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
}
.overlay-content-id {
  display: flex;
  flex: none;
  margin-right: 10px;
  color: black;
  background-color: black;
}
.marked-content {
  background-color: black;
  color: white;
}
.side-bar-icon {
  padding-bottom: 8px;
}

.row-spacing-icon {
  background-image: url("/public/icons/Rows-Spacing-Icon.svg");
  margin-bottom: 8px;
  background-repeat: no-repeat;
  transform: rotate(90deg);
  overflow: visible;
  width: 18px;
  height: 26px;
}

.plant-spacing-icon {
  background-image: url("/public/icons/Plant-Spacing-Icon.svg");
  width: 18px;
  height: 34px;
  margin-bottom: 8px;
  background-repeat: no-repeat;
  transform: rotate(90deg);
  overflow: visible;
}

.eveness-icon {
  background-image: url("/public/icons/Eveness-Icon.svg");
  width: 15px;
  height: 30px;
  margin-bottom: 8px;
  background-repeat: no-repeat;
  transform: rotate(90deg);
  overflow: visible
}
