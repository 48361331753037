.header {
  display: flex;
  font-family: "Montserrat";
}

.date-input {
  margin-right: 1rem;
  width: 330px;
  cursor: default;
}

.mode-input {
  width: 165px;
  margin-right: 1rem;
}
.mode-input2 {
  width: 180px;
  margin-right: 1rem;
}
.mode-input3 {
  width: 200px;
  margin-right: 1rem;
}
