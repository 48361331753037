.marker-overlay {
  position: absolute;
  height: 100vh;
  width: 100%;
  background: radial-gradient(
    circle at 60% 50%,
    transparent 55%,
    rgb(255, 255, 255) 400px
  );
  pointer-events: none;
}

.overlay-wrapper {
  z-index: 9;
  border-color: rgba(0, 0, 0, 0.03);
  border-radius: 2px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 100px;
  width: 40%;
  height: 100vh;
}
.overlay-box1 {
  z-index: 9;
  border-color: black;
  border-radius: 2px;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
}
.overlay-box2 {
  z-index: 9;
  border-color: black;
  border-radius: 2px;
  grid-column-start: 1;
  grid-row-start: 3;
  grid-row-end: 7;
}
.overlay-box3 {
  z-index: 9;
  border-color: black;
  border-radius: 2px;
  grid-column-start: 2;
  grid-row-start: 3;
  grid-row-end: 7;
}

.upper-container {
  display: flex;
  flex-wrap: wrap;
  /* margin-left: 5%; */
  margin-top: 2%;
  justify-content: space-between;
  min-width: 550px;
  width: 650px;
}

.overlay-popup {
  z-index: 12;
}

.close-overlay {
  background-image: url("/public/icons/Close.svg");
  background-color: black;
  background-repeat: no-repeat;
  background-position: center center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin-left: 4px;
  z-index: 9999;
}

.bottom-container {
  margin-left: 10%;
  height: 50%;
  width:50%;
}

